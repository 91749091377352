export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/change-password/[id]": [5,[2]],
		"/(app)/changelog": [6,[2]],
		"/(app)/creatives": [7,[2]],
		"/(app)/creatives/edit/[index]": [8,[2]],
		"/(app)/creatives/new": [9,[2]],
		"/(app)/creatives/preview/[index]": [10,[2]],
		"/(app)/demands": [11,[2]],
		"/(app)/demands/new": [13,[2]],
		"/(app)/demands/[index]": [12,[2]],
		"/login": [26,[3]],
		"/(app)/logout": [14,[2]],
		"/(app)/placements": [15,[2]],
		"/(app)/placements/new": [17,[2]],
		"/(app)/placements/[index]": [16,[2]],
		"/(app)/profile": [18,[2]],
		"/(app)/publishers": [19,[2]],
		"/(app)/publishers/new": [20,[2]],
		"/(app)/reports": [21,[2]],
		"/(app)/settings": [22,[2]],
		"/(app)/users": [23,[2]],
		"/(app)/users/new": [25,[2]],
		"/(app)/users/[id]": [24,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';